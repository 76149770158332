import { Vue } from "vue-property-decorator";

import { CommonModel, QuizModel } from "@/models";

import { ErrorCode } from "@/constant";

type response = { [key: string]: unknown };

class TeCommon extends Vue {
  private static sInstance: TeCommon;
  private token = window.localStorage.getItem("teacherToken") as string;

  public static get instance(): TeCommon {
    if (!TeCommon.sInstance) {
      TeCommon.sInstance = new TeCommon();
    }

    return TeCommon.sInstance;
  }

  public logout() {
    window.localStorage.clear();
    window.location.href = "/teacher/login";
  }

  public checkToken(errNo: number) {
    if (errNo === ErrorCode.InvalidToken) {
      this.logout();
      return;
    }
    return;
  }

  //取得級數
  public async getGrade() {
    const item: { [key: string]: string } = {
      token: this.token
    };
    const data = (await CommonModel.teGetGrade(item)) as response;
    this.checkToken(data.ERR_CODE as number);
    return data.list as object;
  }

  //取得該級數下的等級
  public async getLevel(grade: string) {
    const item: { [key: string]: string } = {
      grade: grade,
      token: this.token
    };
    const data = (await CommonModel.teGetLevel(item)) as response;
    this.checkToken(data.ERR_CODE as number);
    return data.list as object;
  }

  //取得該等級下的單元
  public async getUnit() {
    const list: response = {};
    for (let i = 1; i <= 25; i++) {
      list[i] = { unit: "Unit-" + i };
    }
    list[26] = { unit: "Midterm Exam" };
    list[27] = { unit: "Final Exam" };
    list[28] = { unit: 'VIDEO Q' };
    list[29] = { unit: 'WORKSHOP' };
    return list;
  }

  //取得該老師所屬班級的所有縣市
  public async getTECity(tid: string) {
    const item: { [key: string]: string } = {
      tid: tid,
      token: this.token
    };
    const data = (await CommonModel.getTECity(item)) as response;
    this.checkToken(data.ERR_CODE as number);
    return data.list as object;
  }

  //取得該老師所屬班級之該縣市分校列表
  public async getTESchool(cid: string, tid: string) {
    const item: { [key: string]: string } = {
      cid: cid,
      tid: tid,
      token: this.token
    };
    const data = (await CommonModel.getTESchool(item)) as response;
    this.checkToken(data.ERR_CODE as number);
    return data.list as object;
  }

  //取得該老師所屬班級之該分校班級列表
  public async getTEClass(sid: string, tid: string) {
    const item: { [key: string]: string } = {
      sid: sid,
      tid: tid,
      token: this.token
    };
    const data = (await CommonModel.getTEClass(item)) as response;
    this.checkToken(data.ERR_CODE as number);
    return data.list as object;
  }

  // 取得 該班級的老師列表
  public async getTeacher(type: number, clid: string, tid: number) {
    const item: { type: number; clid: string; tid: number; token: string } = {
      type: type, //1上架的 2全部
      clid: clid,
      tid: tid,
      token: this.token
    };
    const data = (await CommonModel.teGetTeacher(item)) as response;
    this.checkToken(data.ERR_CODE as number);
    return data.list as object;
  }
}

const teCommon = TeCommon.instance;

export { teCommon as TeCommon };
