import { Component, Vue } from "vue-property-decorator";

import { Header,Footer } from "@/layout";
import { APLightbox } from "@/components";
import { ErrorCode, ErrorMessage } from "@/constant";
import { RollcallModel } from "@/models";
import { TeCommon } from "../Common";

export class RollcallForm {
    time = '';
    city = '';
    school = '';
    class = '';
    teacher = '';
    note = '';
    rid = '';
    student: { [key: number]: any } = [];
    token = window.localStorage.getItem('teacherToken') as string;
}

type response = { [key: string]: unknown };

@Component<ApTeacherRollcallRecordsController>({
    components: {
        Header, APLightbox,Footer
    }
})
export default class ApTeacherRollcallRecordsController extends Vue {
    /*定義data 變數 */
    private doubleClick = false;
    private rollcallForm = new RollcallForm();
    private num = 0;
    private token = window.localStorage.getItem('teacherToken') as string;

    private lightbox: { showLB: boolean, errorMsg: string } = {
        showLB: false, 
        errorMsg:  '',
    };

    public async created() {
        const item = {
            rid: this.$route.query.rid,
            token: this.token,
        }
        const editData = await RollcallModel.teGet(item) as response;
        this.assignDataToForm(editData);
        this.num = (!this.rollcallForm.student) ? 0 : Object.keys(this.rollcallForm.student).length;
    }

    private assignDataToForm(data: response) {
        TeCommon.checkToken(data.ERR_CODE as number);

        const item = data.info as {
            rid: string;
            time: string;
            city: string;
            school: string;
            class: string;
            teacher: string;
            note: string;
            student: { [key: number]: any} ;
        };
        this.rollcallForm.rid = item.rid;
        this.rollcallForm.time = item.time;
        this.rollcallForm.city = item.city;
        this.rollcallForm.school = item.school;
        this.rollcallForm.class = item.class;
        this.rollcallForm.teacher = item.teacher;
        this.rollcallForm.note = item.note;
        this.rollcallForm.student = item.student;
    }

    private rollcall(stid: string, status: string) {
        for(const i in this.rollcallForm.student){
            if(this.rollcallForm.student[i]['stid'] == stid){
                this.rollcallForm.student[i]['status'] = status;
            }
        }
    }

    public async validateAndSubmit() {
        if (!this.doubleClick) {
            this.doubleClick = true;
      
            const errNo = await RollcallModel.teEditRecord(this.rollcallForm);
            switch (errNo) {
                case ErrorCode.InvalidToken:
                    TeCommon.logout();
                    break;
                case ErrorCode.Success:
                    this.doubleClick = false;
                    this.$router.push("/teacher/rollcall");
                    break;
                default:
                    this.lightbox.errorMsg = ErrorMessage[errNo];
                    this.lightbox.showLB = true;
                    this.doubleClick = false;
                    break;
            }
        }
    }

    private closeLB() {
        this.lightbox.showLB = false;
    }
}